import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0,3,4,5];

export const dictionary = {
		"/": [~7],
		"/cal/[scheduleId]/[participantId]": [8,[,3],[2]],
		"/cal/[scheduleId]/[participantId]/success": [9,[,3],[2]],
		"/dashboard": [10,[4]],
		"/dashboard/actions": [11,[4]],
		"/dashboard/billing": [12,[4]],
		"/dashboard/billing/success": [13,[4]],
		"/dashboard/calendar-log": [14,[4]],
		"/dashboard/chat": [15,[4]],
		"/dashboard/contacts": [16,[4]],
		"/dashboard/emails": [17,[4]],
		"/dashboard/exec/[execId]": [18,[4]],
		"/dashboard/schedule": [19,[4]],
		"/dashboard/schedule/new": [21,[4]],
		"/dashboard/schedule/[scheduleId]": [20,[4]],
		"/dashboard/settings": [~22,[4]],
		"/dashboard/settings/calendar/[execId]": [23,[4]],
		"/dashboard/tasks": [24,[4]],
		"/dashboard/tasks/new": [27,[4]],
		"/dashboard/tasks/[agendaId]": [25,[4]],
		"/dashboard/tasks/[agendaId]/view": [26,[4]],
		"/dashboard/team": [28,[4]],
		"/dashboard/team/add": [29,[4]],
		"/internal": [30,[5]],
		"/internal/organizations": [~31,[5]],
		"/internal/users": [~32,[5]],
		"/invite/[orgId]/[inviteId]": [~33],
		"/login": [34],
		"/welcome/create-organization": [35,[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';